import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Footer from '../footer/Footer';
import { message } from 'antd';

const ResetPassword = () => {

    // get uid and token
    const params = useParams();

    // to handle redirects
    const navigate = useNavigate();

    // Form Data state
    const [formdata, setFormData] = useState({
        uid: "",
        token: "",
        new_password: "",
        re_new_password: ""
    })

    useEffect(() => {
        setFormData({
            uid: params.uid,
            token: params.token,
            new_password: "",
            re_new_password: ""
        });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    // handle form input change
    const handleFormChange = (event) => {
        setFormData({ ...formdata, [event.target.name]: event.target.value });
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (formdata.new_password !== formdata.re_new_password) {
            message.error("Passwords do not match!");
            return;
        }
        console.log(formdata);
        axios.post(process.env.REACT_APP_SERVER_URL.concat("api/v1/auth/users/reset_password_confirm/"), formdata)
            .then((response, request) => {
                message.success("Account created Successfully. Please Log In");
                navigate('/login');
            })
            .catch((error) => {
                try {
                    if(error.response.status === 400) {
                        console.log(error.response.headers)
                        if(error.response.headers['content-length'] === "58" ) {
                            message.error(`User with email ${formdata.email} already exists`)
                        } else {
                            message.error(`Password too common / similar to username`)
                        }
                    }
                } catch {
                    message.info("Server under maintenance, please try again later")
                }
               
            })
    }


    return (
        <div className='w-screen h-screen'>
            <div className='pt-[3%]'>
                <div className='mx-auto md:w-[40rem] w-[30rem] bg-[#FFF] px-[2rem] py-[2rem] rounded-lg shadow-lg'>
                    <p className='text-[2rem] text-purple-500 text-center'>Sign up</p>
                    <form onSubmit={(event) => handleFormSubmit(event)}>
                        <div className="flex flex-col my-[1.5rem]">
                            <label for="password" className='text-purple-500 text-[1.2rem]'>New Password</label>
                            <input type="password" required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-purple-500 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" name="new_password" id="new_password" placeholder="Password" onChange={event => handleFormChange(event)} />
                        </div>
                        <div className="flex flex-col my-[1.5rem]">
                            <label for="password" className='text-purple-500 text-[1.2rem]'>Confirm Password</label>
                            <input type="password" required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-purple-500 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" name="re_new_password" id="re_new_password" placeholder="Re-type Password" onChange={event => handleFormChange(event)} />
                        </div>

                        <button type="submit" className="px-[1.2rem] py-[0.5rem] bg-purple-500 text-white rounded-lg mr-[0.5rem]">Reset Password</button>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ResetPassword;