import React, { useRef, useState } from "react";
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
import {Tooltip, message } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import LineChart from "./LineChart";

const FindContactFrame = () => {

    // Refs
    const inputFileRef = useRef(null);

    // States

    // State to send request to server
    const [requestData, setRequestData] = useState({
        forcePlateName: "",
        textFile: "",
    })

    // Visibility handler
    const [showGraph, setShowGraph] = useState(false);

    // Graphs States
    const [ForceData, setForceData] = useState([]);


    // Methods

    // Handle click on file inputs
    const handleInputClick = (event, id) => {
        event.preventDefault();
        const inputElement = document.getElementById(id);
        if (inputElement) {
            inputElement.click();
        }

    }

    // Handle force plate file change
    const handleFileChange = (event) => {
        setRequestData({ ...requestData, [event.target.name]: event.target.files[0] })
    }

    // Handle Form Submit
    const handleUpload = (e) => {
        e.preventDefault();
        if(requestData.textFile === '') {
            message.error("Please select a Force File")
            return
        }
        const key = 'updatable';
        message.open({
            key,
            type: 'loading',
            content: 'Generating Graphs'
        })

        axios.post(process.env.REACT_APP_SERVER_URL.concat("api/v1/force_file_visualizer/"), requestData, {
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response, _) => {         
                setShowGraph(true)
                message.open({
                    key,
                    type: 'success',
                    content: 'Visualizations Generated'
                })

                setForceData(response.data)
            })
            .then(() => {
                setShowGraph(true)
            })
            .catch((error) => {
                message.open({
                    key,
                    type: 'error',
                    content: "Cannot Process this Force File. Please check the Force file."
                })
            })
    }

    return (
        <>
            <Navbar />
            <Sidebar />
            <div className='ml-[12rem] lg:ml-[12%] pt-[5rem] bg-[#eeeeee] h-screen'>
                <h3 className='ml-[1rem] text-[3rem]' >Find Contact Frame</h3>
                <div className="bg-[#eeeeee]">
                    <div className="w-[80%] mt-[2%] mx-auto bg-white shadow-md rounded-lg px-[2rem] py-[2rem]">

                        {/* div to pack video data into a request */}
                        <form enctype="multipart/form-data">
                            <Row>
                                <Col md={12}>
                                    <p>
                                        Upload Force Plate details &nbsp;&nbsp;
                                        <Tooltip placement="top" title={"Upload a force file"}>
                                            <InfoCircleOutlined style={{ color: "#666666" }} />
                                        </Tooltip>
                                    </p>
                                    <div className="relative">
                                        <button onClick={(event) => { handleInputClick(event, 'force_input') }} class="absolute bg-white border-2 border-purple-500 text-pruple-500 w-[7.2rem] py-[0.3rem] rounded hover:cursor-pointer z-10">
                                            Choose File
                                        </button>
                                        <input
                                            type="file"
                                            id="force_input"
                                            className="z-5 placeholder-pl-10 pl-[1rem] pt-[0.2rem]"
                                            placeholder="Upload details of force plate"
                                            name="textFile"
                                            ref={inputFileRef}
                                            onChange={event => handleFileChange(event)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <br />

                            <br />
                            <button
                                type=""
                                className="bg-purple-400 rounded-lg w-[10rem] h-[2rem] text-white border-none"
                                onClick={(event) => handleUpload(event)}
                            >
                                Visualize Force
                            </button>
                        </form>


                        <br /><br /><br /><br /><br />
                        {showGraph ?
                            <>
                                <LineChart lineChartData={ForceData} /></>
                            :
                            <></>
                        }
                    </div>
                </div>
            </div>

        </>
    )
}

export default FindContactFrame