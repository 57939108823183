import React, { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Input, Select, message } from "antd";
import { Col, Row } from "react-bootstrap";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);



const LineChart = (lineChartData) => {

    // states
    const [legendList, setLegendList] = useState([]);
    const [selectedLegend, setSelectedLegend] = useState('');
    const [dataRange, setDataRange] = useState({
        start: 0,
        end: 0,
    })

    // Chart states and constants

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: 'Force-Time Curve',
            },
        },
        elements: {
            point: {
                //   radius: 0,
                pointStyle: false,
            },
        },
    };
    // scales: {
    //     x: {
    //         display: false, // Set to false to hide the x-axis labels
    //     },
    //     y: {
    //         beginAtZero: true, // Adjust this as needed
    //     },
    // },

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    })

    useEffect(() => {
        setDataRange({
            start: 0,
            end: lineChartData.lineChartData.rawData.Time.length
        })


        // variable to store Chart Data
        var dataConstructor = {
            labels: [],
            datasets: [],
        }

        // Selecting second key automatically (first key is time)
        var legendListConstructor = [];
        for (const key in lineChartData.lineChartData.rawData) {
            if (lineChartData.lineChartData.rawData.hasOwnProperty(key)) {
                if (key === "Time") {
                    continue;
                }
                legendListConstructor.push({ value: key, label: key });
            }
        }
        setSelectedLegend(legendListConstructor[0].value)

        var skip = parseInt(lineChartData.lineChartData.rawData.Time.length/750) + 1
        const rawData = lineChartData.lineChartData.rawData[legendListConstructor[0].value];
        const displayedData = [];
        for (let i = 0; i < rawData.length; i += skip) {
            displayedData.push(rawData[i]);
        }

        const labelConstructor = [];
        for (let i = 0; i < lineChartData.lineChartData.rawData.Time.length; i += skip) {
            labelConstructor.push((i+1).toString());
        }

        dataConstructor.labels = labelConstructor
        dataConstructor.datasets.push({
            label: legendListConstructor[0].value,
            data: displayedData,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        })

        setChartData(dataConstructor);
        setLegendList(legendListConstructor);

    }, [lineChartData])


    // Methods

    // Handle Legend Change 
    const handleLegendChange = (value) => {
        setDataRange({
            start: 0,
            end: lineChartData.lineChartData.rawData.Time.length
        })
        setSelectedLegend(value)
        var skip = parseInt(lineChartData.lineChartData.rawData.Time.length/750) + 1
        const rawData = lineChartData.lineChartData.rawData[value];
        const displayedData = [];
        for (let i = 0; i < rawData.length; i += skip) {
            displayedData.push(rawData[i]);
        }
        const labelConstructor = [];
        for (let i = 0; i < lineChartData.lineChartData.rawData.Time.length; i += skip) {
            labelConstructor.push((i+1).toString());
        }
        setChartData({
            labels: labelConstructor,
            datasets: [{
                data:displayedData,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }]
        })
    }

    // Handle Range Change
    const handleRangeChange = (e) => {
        if(e.target.value === "" || e.target.value==="-") { 
            setDataRange({ ...dataRange, [e.target.name]: "" })
        } else {
            setDataRange({ ...dataRange, [e.target.name]: parseInt(e.target.value) })
        }
        console.log(e.target.value)
    }

    // Set New Zoom and regenerate chart
    const resetRange = () => {
        try {
        // Generate New labels
        if(dataRange.start === "" || dataRange.start<0 || dataRange.end === "" || dataRange.end>lineChartData.lineChartData.rawData.Time.length || dataRange.end < dataRange.start ) {
            message.error("Invalid Frame Range")
            return
        }
        
        const rawData = lineChartData.lineChartData.rawData[selectedLegend].slice(dataRange.start, dataRange.end);
        // console.log("len", rawData)
        var skip = parseInt(rawData.length/750) + 1

        const displayedData = [];
        for (let i = 0; i < rawData.length; i += skip) {
            displayedData.push(rawData[i]);
        }
        console.log(displayedData)
        const labelConstructor = [];
        for (let i = dataRange.start; i <= dataRange.end; i += skip) {
            labelConstructor.push((i).toString());
        }
        setChartData({
            labels: labelConstructor,
            datasets: [{
                data:displayedData,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }]})
        } catch (error) {
            console.log(error)
        }
    }



    return (
        <>
            <Row>
                <Col md={12}>
                    Select Parameter
                    <Select
                        style={{ width: '100%' }}
                        placeholder={"Select Legend"}
                        options={legendList}
                        value={selectedLegend}
                        onChange={handleLegendChange}
                    />
                </Col>
            </Row>
            <br />
            <Row>
                <Col md={5}>
                    Frame Start Point
                    <Input
                        type="number"
                        placeholder="Data Range Starting Point"
                        name="start"
                        value={dataRange.start}
                        onChange={event => handleRangeChange(event)}
                    />
                </Col>
                <Col md={5}>
                    Frame End Point
                    <Input
                        type="input"
                        placeholder="Data Range Ending Point"
                        name="end"
                        value={dataRange.end}
                        onChange={event => handleRangeChange(event)}
                    />
                </Col>
                <Col md={2}>
                    <br />
                    <button
                        type=""
                        className="bg-purple-400 rounded-lg w-[10rem] h-[2rem] text-white border-none"
                        onClick={resetRange}
                    >
                        Update Range
                    </button>
                </Col>
            </Row>
            <br />
            <Line options={options} data={chartData} />
        </>
    )
}

export default LineChart;